.table-pagination{
    margin-top: 15px;
    margin-bottom: 15px;
}
.containerBigTable.MuiContainer-maxWidthLg{
    margin-top: 15px;
    padding-left: 0;
    padding-right: 0;
}

.containerBigTable.MuiContainer-maxWidthLg .buscador{
    text-align: center;
}
.containerBigTable.MuiContainer-maxWidthLg .titulo h2{
    margin: 0;
}
.containerBigTable.MuiContainer-maxWidthLg .titulo p{
    color: #949494;
    font-size: 0.9rem;
}
.tableContainer{
    overflow-x: auto;
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
}
table.formato1{
    width: 100%;
    margin: 0;
    padding:0;
    border:none;
}
table.formato1 thead tr th,
table.formato1 tbody tr td{
    border: 0;
    padding: 3px;
    font-size: 0.8rem;
    border-top: 1px solid #ccc;
}
table.formato1 thead tr{
    background-color: #242424;
    color: #fff;
}
table.formato1 thead tr th{
    font-weight: 500;
    padding: 15px 3px;
    letter-spacing: 0.01071em;
    font-size: 0.875rem;
    border:1px solid #242424;
    padding: 16px;
}
table.formato1 thead tr th.ordenable:hover{
    cursor: pointer;
    background-color: #4a4a4a;
}
table.formato1 tbody tr:nth-child(even){
    background-color: rgb(240, 240, 240);
}
table.formato1 tbody tr:nth-child(odd){
    background-color: #fff;
}
table.formato1 tbody tr td{
    font-weight: 400;
    padding: 3px 16px;
    letter-spacing: 0.01071em;
    font-size: 14px;
}
table.formato1 tbody tr:hover{
    background-color: rgb(255, 250, 205);
}
.tablaM thead{
    background-color: #242424;
}
.tablaM thead th{
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.tablaM tbody td{
    font-weight: 400;
}
@media screen and (min-width: 768px) {
    .tableContainer{
        margin-top: 15px;
    }
    
    .containerBigTable.MuiContainer-maxWidthLg .buscador{
        text-align: right;
    }
}

