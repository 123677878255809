@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

.fondoLogo{
  position:fixed;
  left: -35px;
  top: -35px;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.doyturnos.com.ar/images-adm/fondo.png);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  z-index: -1;
}

h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea, select{
  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.paper{
  text-align: center;
  padding: 10px 15px;
}
.error{
  color:rgb(158, 7, 7);
}

.MuiButton-root.MuiButton-contained,
.MuiButton-root.MuiButton-outlined{
  font-family: 'Open Sans', sans-serif;
}

.vistaFormulario .footerFormulario{
  margin-top: 15px;
  border-top: 1px solid #ccc;
}

/*input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
}*/