.MuiContainer-root.Headervista {
    display: inline-block;
    margin: 0;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.Headervista .titulo {
    text-align: center;
    margin-top: 10px;
}

.Headervista .titulo div {
    justify-content: center;
}

.Headervista .titulo {
    text-align: center;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
}

.Headervista a:hover, .Headervista a:active{
    text-decoration: underline;
}

.Headervista .MuiSvgIcon-root {
    font-size: 2.3rem;
}

.Headervista .start {
    text-align: center;
    margin-top: 10px;
}

.Headervista .end {
    text-align: center;
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .Headervista .titulo {
        text-align: left;
        margin-top: 0;
    }

    .Headervista .titulo div {
        justify-content: left;
    }

    .Headervista .titulo {
        text-align: center;
        margin-top: 0;
    }

    .Headervista .start {
        text-align: left;
        margin-top: 15px;
        padding-left: 24px;
    }

    .Headervista .end {
        text-align: right;
        margin-top: 15px;
        padding-right: 24px;
    }
}