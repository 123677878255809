@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

#sidebar {
    background: #F4F4F4;
    height: calc(100% - 100px);
    width: 250px;
    margin: 0;
    padding: 0;
    padding-top: 100px;
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    border-right: #ccc solid 1px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    transition: all 0.3s;
}

#sidebar .css-cveggr-MuiListItemIcon-root {
    min-width: 35px;
}

#sidebar.closed {
    width: 0;
}

#sidebar .unElementoPadre {
    border-top: 1px solid #ccc;
}

#sidebar .unElementoHijo {
    background-color: #fff;
}

#sidebar .contenido {
    background-color: #fdedec;
    color: #808080;
}

#sidebar .contenido:hover {
    background-color: #fadbd8;
}

#sidebar .clientes {
    background-color: #ebf5fb;
    color: #808080;
}

#sidebar .clientes:hover {
    background-color: #d6eaf8;
}

#sidebar .caja {
    background-color: #f5f5ff;
    color: #808080;
}

#sidebar .caja:hover {
    background-color: #e6e6ff;
}

#sidebar .configuracion {
    background-color: #e8f6f3;
    color: #808080;
}

#sidebar .abonados {
    background-color: #ebf5fb;
    color: #808080;
}

#sidebar .abonados:hover {
    background-color: #d6eaf8;
}

/*
#sidebar ul {
    padding:0;
    width: 100%;
    height: auto;
    margin-top: 90px;
}

#sidebar ul li.sidebar-row {
    width: 100%;
    height: 40px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: #808080;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ccc;
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
}

#sidebar ul a{
    text-decoration: none;
}

#sidebar ul li.sidebar-row:hover {
    cursor: pointer;
    background-color:#FFE600;
    color: #000;
}

#sidebar ul li.sidebar-row div.icono {
    flex: 20%;
    display: grid;
    place-items: center;
}

#sidebar ul li.sidebar-row div.titulo {
    flex: 80%;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
}

#sidebar ul div.hijoCerrado .sidebar-row{
    height: 0px;
    -webkit-transition: height 0.22s linear;
       -moz-transition: height 0.22s linear;
        -ms-transition: height 0.22s linear;
         -o-transition: height 0.22s linear;
            transition: height 0.22s linear;
}
#sidebar ul div.hijoCerrado{
    visibility: hidden;
    transition: visibility .150s;
}

#sidebar ul div.hijoAbierto .sidebar-row{
    height: 40px;
     -webkit-transition: height 0.22s linear;
        -moz-transition: height 0.22s linear;
         -ms-transition: height 0.22s linear;
          -o-transition: height 0.22s linear;
             transition: height 0.22s linear;
}
#sidebar ul div.hijoAbierto{
    visibility: visible;
}

#sidebar ul li.sidebar-row .icono svg{
    font-size: 1.2rem;
}

#sidebar ul li.sidebar-row.hijo{
    padding-left: 0px;
    background-color: #fff;
}
#sidebar ul li.sidebar-row.hijo:hover {
    cursor: pointer;
    background-color:#FFE600;
    color: #000;
}
#sidebar ul li.sidebar-row.hijo .icono {
    padding-left: 20px;
}*/